<template>
  <div class="ArticleType">
    <div class="navC">
      <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 100%;">
        <el-tab-pane label="教师文章管理" name="1"></el-tab-pane>
        <el-tab-pane label="文章分类管理" name="2"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="contentBox">
      <!-- 筛选 -->
      <div class="selectTop">
        <div class="lBox">
          <span>分类名称：</span>
          <el-input v-model="form.search_name" placeholder="请输入关键词" class="input"></el-input>
          <el-button type="primary" @click="ArticleType()">查询</el-button>
          <el-button @click="clearAll()">重置</el-button>
        </div>
        <div class="rBox">
          <el-button class="btn" @click="openAdd()">添加分类</el-button>
        </div>
      </div>
      <!-- 表格 -->
      <div class="tableList">
        <el-table :data="tableData" style="width: 1054px">
          <el-table-column prop="date" label="序号" align="center" width="110">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="分类名称" align="center" width="230" />
          <el-table-column prop="title_type" label="类型" align="center" width="230" />
          <el-table-column prop="title_status" label="状态" align="center" width="230" />
          <el-table-column label="操作" align="center" width="250">
            <template slot-scope="scope">
              <span @click="onUpdate(scope.row)" v-if="scope.row.title_type != '系统默认'">修改</span>
              <span v-if="scope.row.title_status == '显示'" @click="HiddenAndShow(scope.row.id)">隐藏</span>
              <span v-if="scope.row.title_status == '隐藏'" @click="HiddenAndShow(scope.row.id)">显示</span>
              <span v-if="scope.row.title_type != '系统默认'" @click="deleteType(scope.row.id)">删除</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="form.page" :page-size="form.limit" layout="prev, pager, next,jumper" :total="total">
        </el-pagination>
      </div>
      <!-- 添加分类弹框 -->
      <el-dialog title="添加/修改分类" :visible.sync="Visible" style="width: 850px; margin: 0 auto">
        <el-input v-model="form1.name" style="width: 350px" placeholder="请输入内容"></el-input>
        <div style="margin-top: 10px; margin-left: 200px">
          <el-button type="primary" @click="addType()" v-if="type == 1" :disabled="onShow">确定</el-button>
          <el-button type="primary" @click="toUpload()" v-if="type == 2" :disabled="onShow">确定</el-button>
          <el-button @click="CloseAdd()">取消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: "",
      onShow: false, //延迟控制变量
      type: 1, //默认添加，2是修改
      //弹框显示
      Visible: false,
      tableData: [],
      //总数
      total: 0,
      //表单数据
      form: {
        page: 1,
        limit: 10,
        search_name: "",
        teaching_studio_id: localStorage.getItem("studioId"),
      },

      //添加数据表单
      form1: {
        name: "",
        teaching_studio_id: localStorage.getItem("studioId"),
      },
      activeName: '2',
    };
  },
  created() {
    let rouName = this.$route.name
    this.$emit('rouname', rouName)
    this.init();
  },
  computed: {},
  methods: {
    handleClick() {
      this.$router.push({
        path: "/ArticleList"
      })
    },
    //添加分类
    addType() {
      this.$axios.post("TeachingStudioArticleType/add", this.form1).then(() => {
        setTimeout(() => {
          this.$message.success("添加分类成功");
          this.form1.name = "";
          this.Visible = false;
          this.onShow = true;
          //延迟三秒
          setTimeout(() => {
            this.onShow = false;
          }, 3000);
          //初始化
          this.init();
        }, 1000);
      });
    },

    //关闭分类弹框
    CloseAdd() {
      this.form1.name = "";
      this.Visible = false;
    },

    //显示添加
    openAdd() {
      this.type = 1;
      this.Visible = true;
    },

    //显示修改
    onUpdate(row) {
      this.id = row.id;
      this.form1.name = row.name;
      this.type = 2;
      this.Visible = true;
    },
    //修改(未完成)
    toUpload() {
      let obj = {
        id: this.id,
        name: this.form1.name,
      };
      this.$axios.put("TeachingStudioArticleType/edit", obj).then(() => {
        this.$message.success("修改成功");
        this.Visible = false;
        this.onShow = true;
        //延迟三秒
        setTimeout(() => {
          this.onShow = false;
        }, 3000);
        this.init();
      });
    },
    //初始化
    init() {
      this.ArticleType();
    },
    //重置数据
    clearAll() {
      this.form.page = 1;
      this.form.limit = 10;
      this.form.search_name = "";
      this.ArticleType();
    },
    //初始化列表
    ArticleType() {
      this.$axios
        .get("TeachingStudioArticleType/list", {
          params: this.form,
        })
        .then((res) => {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
        });
    },

    //隐藏和显示分类
    HiddenAndShow(id) {
      this.$axios
        .put("TeachingStudioArticleType/changeStatus", {
          id: id,
        })
        .then(() => {
          this.$message.success("设置成功");
          this.ArticleType();
        });
    },

    //删除分类
    deleteType(id) {
      this.$axios
        .delete("TeachingStudioArticleType/delTeachingStudioArticleType", {
          params: {
            id: id,
          },
        })
        .then((res) => {
          this.$message.success(res.data.msg);
          this.ArticleType();
        });
    },

    handleSizeChange(val) {
      this.form.limit = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.init();
    },
  },
};
</script>
<style lang="less" scoped>
.ArticleType {
  .navC {
    width: 100%;
    height: 60px;
    padding-left: 26px;
    display: flex;
    align-items: end;
    border-bottom: 1px solid #f0f0f0;
    justify-content: space-between;
    box-sizing: border-box;

    /deep/.el-tabs__header {
      margin: 0;

      .el-tabs__item {
        font-size: 16px;
        margin-bottom: 8px;
      }

      .el-tabs__item.is-active {
        font-weight: bold;
      }
    }
  }

  //内容盒子
  .contentBox {
    padding: 26px 26px;

    .selectTop {
      display: flex;
      justify-content: space-between;

      .lBox {
        .input {
          width: 216px;
          margin-right: 39px;
        }
      }

      .rBox {
        .btn {
          width: 102px;
          height: 34px;
          background: #ff8201;
          border: 1px solid #ff8201;
          border-radius: 4px;
          display: flex;
          align-items: center;
          color: #fff;
        }
      }
    }

    //表格
    .tableList {
      width: 1054px;
      margin-top: 24px;
      border: 1px solid #ebeef5;
      border-bottom: none;

      span {
        margin-right: 30px;
        color: #3489fe;
        cursor: pointer;
      }
    }

    //样式
    .block {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
  }
}</style>
